export function calculateAscenderHeightRatio(elem) {
  const computedStyle = getComputedStyle(elem)

  // The container is a little defensive.
  const container = document.createElement('div')
  container.style.display = 'block'
  container.style.position = 'absolute'
  container.style.bottom = '0'
  container.style.right = '0'
  container.style.width = '0px'
  container.style.height = '0px'
  container.style.margin = '0'
  container.style.padding = '0'
  container.style.visibility = 'hidden'
  container.style.overflow = 'hidden'
  container.style.fontSize = computedStyle.fontSize
  container.style.fontWeight = computedStyle.fontWeight
  container.style.fontFamily = computedStyle.fontFamily

  const small = document.createElement('span')
  const large = document.createElement('span')

  // Large numbers help improve accuracy.
  small.style.fontSize = '0px'
  large.style.fontSize = '2000px'

  small.innerHTML = 'h'
  large.innerHTML = 'h'

  container.appendChild(small)
  container.appendChild(large)

  // Put the element in the DOM for a split second.
  document.body.appendChild(container)
  const smalldims = small.getBoundingClientRect()
  const largedims = large.getBoundingClientRect()
  document.body.removeChild(container)

  // Calculate where the ascender was, percentage-wise.
  const ascenderPosition = smalldims.top - largedims.top
  const height = largedims.height

  return 1 - ascenderPosition / height
}
